import {doViewsLogin} from "@/service/views/common";

export default [
    {
        path:"/",
        component:()=> import('@/layout/Views.vue'),
        children:[
            {
                path: '/',
                component: ()=> import('@/views/Index.vue'),
                meta: {title: '首页',background:'#dcf3ff'},
            },
            {
                path: '/video/pc',
                component: ()=> import('@/views/video/Pc.vue'),
                meta: {title: '新品速递',background:'#fff'},
            },
            {
                path: '/video/m',
                component: ()=> import('@/views/video/M.vue'),
                meta: {title: '新品速递',background:'#fff'},
            },
        ],
        beforeEnter:async (to,from,next)=>{
            //await doViewsLogin();
            next();
        }
    },
    {
        path: '*',
        component: ()=> import('@/views/Error.vue'),
        meta: {title: '错误'},
    }
]
