<template>
	<span class="wrap">
		<el-input v-if="show_edit" :placeholder="text" v-model="text" class="input-with-select">
			<el-button slot="append" class="el-icon-check" @click="change"></el-button>
		</el-input>
		<span v-else style="cursor: pointer;" class="wrap" @click="show">{{number}} <i class="icon el-icon-edit"/></span>
	</span>
</template>

<script >
import _ from "lodash";
export default {
	props:{
		number:"",
	},
	data(){
		return {
			show_edit:false,
			focus:false,
			text:"",
		}
	},
	watch:{
		number:{
			handler(n){
				this.text = _.cloneDeep(n)
			},
			immediate:true,
		}
	},
	methods:{
		change(){
			this.$emit('change',this.text);
			this.hidden();
		},
		show(){
			this.show_edit = true;
		},
		hidden(){
			this.show_edit = false;
		}
	}
}
</script>

<style lang="scss" scoped>
.wrap{display: flex;align-items: center;}
.icon{display: inline-block; font-size: 18px; margin-left: 10px;}
</style>
