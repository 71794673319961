<template>
	<main>
		<el-dialog title="标题" :visible="visible" @close="close" :append-to-body="true">
			<div class="table">
				<el-table :data="list" border height="400">
					<el-table-column label="序号" prop="index" width="50">
						<template slot-scope="scope">{{scope.$index+1}}</template>
					</el-table-column>
					<el-table-column v-for="(item,index) in import_field" :key="index" :label="item.label" :prop="item.prop">
						<template slot-scope="scope">
							<el-input type="textarea" autosize resize="none" v-model="scope.row[item.prop]"/>
						</template>
					</el-table-column>
					<el-table-column label="操作" prop="action" width="100">
						<template slot-scope="scope">
							<div class="el-link-box">
								<el-link type="primary" @click="remove(scope.$index)">删除</el-link>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取 消</el-button>
				<el-button type="primary" @click="submit">确 定</el-button>
			</div>
		</el-dialog>
	</main>
</template>

<script >
export default {
	props:{
		import_field:{
			type:Array,
			default:[]
		}
	},
	data(){
		return {
			visible:false,
			list:[],
		}
	},
	methods:{
		init(list = []){
			this.list = list;
			this.visible = true;
		},
		submit(){
			if(this.list.length <= 0){
				this.$message.error('未检测到任何数据');return false;
			}
			this.$emit('saveAll',this.list)
		},
		remove(index){
			this.list.splice(index,1);
		},
		close(){
			this.visible = false;
		}
	}
}
</script>


