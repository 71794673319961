import Vue from 'vue'
import Vuex from 'vuex'
import views from "@/store/modules/views";
import admin from "@/store/modules/admin";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {

    },
    mutations: {

    },
    actions: {

    },
    modules: {
        views,admin
    }
})
