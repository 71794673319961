<template>
	<span v-if="detail">
		<el-tag v-if="detail.type" :type="detail.type || ''">{{detail.title || '-'}}</el-tag>
		<span v-else>{{detail.title || '-'}}</span>
	</span>
</template>

<script >
export default {
	props:{
		option:{
			type:Array,
			default:[],
		},
		value:""
	},
	computed:{
		detail(){
			if(this.option && this.option.length > 0){
				return this.option.find((item,index)=>{
					return Number(item["id"]) === Number(this.value);
				})
			}else{
				return  ''
			}
		}
	}
}
</script>
