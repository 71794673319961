import Vue from "vue";

import AImage from "@/components/views/common/Image.vue"
import ALink from "@/components/views/common/Link.vue"
import APage from "@/components/views/common/Page.vue"
import ASection from "@/components/views/common/Section.vue"

Vue.component('AImage',AImage);
Vue.component('ALink',ALink);
Vue.component('APage',APage);
Vue.component('ASection',ASection);
