<template>
	<main>
		<div class="cropper" v-show="image">
			<div class="wrap">
				<div class="do">
					<vueCropper
							ref="cropper"
							:img="image"
							:outputSize="option.outputSize"
							:outputType="option.outputType"
							:mode="option.mode"
							:info="option.info"
							:full="option.full"
							:canMove="option.canMove"
							:canMoveBox="option.canMoveBox"
							:fixedBox="option.fixedBox"
							:autoCrop="option.autoCrop"
							:autoCropWidth="option.autoCropWidth"
							:autoCropHeight="option.autoCropHeight"
							:centerBox="option.centerBox"
							:infoTrue="option.infoTrue"
							:enlarge="option.enlarge"
							:high="option.high"
							:original="option.original"
							:style="style"
					></vueCropper>
				</div>
				<div class="action">
					<div class="btn" @click="error('操作取消')">
						<div class="tit">取消</div>
					</div>
					<div class="btn" @click="cropperRotate">
						<div class="tit">旋转</div>
					</div>
					<div class="btn" @click="cropperClean">
						<div class="tit">还原</div>
					</div>
					<div class="btn" @click="getCropperData">
						<div class="tit">确认</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import { VueCropper }  from 'vue-cropper'
export default {
	components:{VueCropper},
	props:{
		file:{
			default:''
		},
		size:{
			type:String,
			default:""
		},
	},
	data() {
		return {
			thumb_file:"",
			image:"",
			image_size:{
				width:0,
				height:0
			}
		}
	},
	computed:{
		size_px(){
			if(!this.size){
				return {
					width:750,
					height:750,
				};
			}
			var size = this.size.replace(/px/g,'').split('*');
			return {
				width:size[0],
				height:size[1],
			}
		},
		style(){
			var style = {
				width:(this.option.autoCropWidth > this.image_size.width ? this.option.autoCropWidth : this.image_size.width)+'px',
				height:(this.option.autoCropHeight > this.image_size.height ? this.option.autoCropHeight : this.image_size.height)+'px',
			}
			if(this.image_size.width >= this.image_size.height){
				delete style.height;
			}
			return style
		},
		option(){
			return {
				mode:"cover",
				outputType:"png",
				outputSize:0.8,
				info:true,
				full:false,
				canMove:true,
				canMoveBox:true,
				fixedBox:true,
				autoCrop:true,
				autoCropWidth:parseInt(this.size_px.width),
				autoCropHeight:parseInt(this.size_px.height),
				centerBox:false,
				infoTrue:true,
				original:true,
				enlarge:1,
				high:false,
			}
		}
	},
	methods: {
		getCropperData(){
			this.$refs.cropper.getCropBlob((blod) => {
				var file = new window.File([blod], this.thumb_file.name, {type:this.thumb_file.type,uid:this.thumb_file.uid});
				this.success(file);
			})
		},
		cropperRotate(){
			this.$refs.cropper.rotateRight()
		},
		cropperClean(){
			this.$refs.cropper.rotate = 0;
			this.$refs.cropper.reload();
		},
		fileToBlob(file){
			return new Promise((resolve,reject)=>{
				var reader = new FileReader();
				var blob = null;
				reader.onload = (e) => {
					if (typeof e.target.result === 'object') {
						blob = window.URL.createObjectURL(new Blob([e.target.result]));
					} else {
						blob = e.target.result
					}
					resolve(blob)
				}
				reader.readAsArrayBuffer(file);
			})
		},
		init(file){
			this.thumb_file = file;
			this.fileToBlob(file).then((blob)=>{
				var img = new Image();
				img.src = blob;
				img.onload =()=>{
					this.image_size = {
						width:img.width,
						height:img.height
					}
					this.image = blob;
				}
			}).catch((res)=>{
				this.error('载入图片失败')
			})
		},
		error(message){
			this.$emit('error',message);
			this.reset();
		},
		success(file){
			this.$emit('success',file);
			this.reset();
		},
		reset(){
			this.image = "";
		}
	}
}
</script>

<style lang="scss" scoped>
.cropper{position: fixed;top:0;left:0;width: 100%;height:100%;z-index: 10000;background:rgba(0,0,0,.8);
	.wrap{position: absolute;top:50%;left:50%;transform: translate(-50%,-50%);
		.do{flex: 1;position: relative;text-align: center;
			.vue-cropper{width: 960px;height:540px; display: inline-block;transform: scale(.5);max-height:540px;max-width:1920px;
				::v-deep .crop-info{font-size: 28px;line-height: 40px;}
			}
		}
		.action{display: flex;justify-content: space-between;margin: 20px auto 0;width: 500px;
			.btn{flex: 1; color:#fff;font-size: 20px;text-align: center;cursor: pointer;padding: 0 20px;
				.tit{display: inline-block;padding-left: 40px;}
			}
			.btn:hover{
				.tit{font-weight: bold;}
			}
			.btn:nth-child(1){
				.tit{background: url("~@/assets/admin/image/close.png") no-repeat left center;background-size: 30px;}
			}
			.btn:nth-child(2){
				.tit{background: url("~@/assets/admin/image/rotate.png") no-repeat left center;background-size: 30px;}
			}
			.btn:nth-child(3){
				.tit{background: url("~@/assets/admin/image/reset.png") no-repeat left center;background-size: 30px;}
			}
			.btn:nth-child(4){
				.tit{background: url("~@/assets/admin/image/success.png") no-repeat left center;background-size: 30px;}
			}
		}
	}
}
</style>

