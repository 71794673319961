<template>
	<div class="ckeditor">
		<div id="ckeditor"></div>
	</div>
</template>

<script >
import {uploadFile} from "@/plugins/ckeditor/upload";

export default {
	props:{
		value:"",
	},
	data(){
		return {
			editor:"",
		}
	},
	mounted() {
		this.initCkeditor()
	},
	methods:{
		setValue(value){
			this.$emit('input',value)
		},
		initCkeditor(){
			ClassicEditor.create(document.querySelector('#ckeditor')).then(editor => {
				const body = editor.ui.view.body._bodyCollectionContainer
				body.remove()
				editor.ui.view.element.appendChild(body)

				editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
					return new uploadFile(loader);
				}
				editor.model.document.on('change:data', () => {
					this.setValue(editor.getData())
				});
				this.editor = editor
			}).catch(error => {
				console.error(error);
			})
		}
	},
	beforeDestroy() {
		this.editor.setData("")
	}
}
</script>

<style lang="scss" scoped>
.ckeditor{overflow: hidden;}
</style>
