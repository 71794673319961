import Vue from 'vue'

Vue.directive('click-outside', {
	bind(el,binding, vnode) {
		const clickHandler = (e) =>{
			if (el.contains(e.target)) {
				return false;
			}
			if (binding.value) {
				binding.value(e);
			}
		}
		el.__vueClickOutside__ = clickHandler;
		document.addEventListener('click', clickHandler);
	},
	unbind(el){
		document.removeEventListener('click', el.__vueClickOutside__);
		delete el.__vueClickOutside__;
	}
})
