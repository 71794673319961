<template>
	<div class="search-box">
		<el-form :inline="true" :model="modelValue" class="demo-form-inline">
			<template v-for="(item,index) in column" >
				<template v-if="item.search">
					<el-form-item :key="index" v-if="item.type === 'option'">
						<el-select v-model="modelValue[item.prop]" :placeholder="item.label" :clearable="true">
							<el-option :label="v.title" :value="v.id" v-for="(v,i) in item.option" :key="i"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :key="index" v-else-if="item.type === 'cascader'">
						<el-cascader class="el-block" v-model="modelValue[item.prop]" :clearable="true" separator="-" :options="item.option" :props="{value:'id',label:'title',emitPath:false}" ></el-cascader>
					</el-form-item>
					<el-form-item :key="index" v-else-if="item.type === 'date'">
						<el-date-picker v-model="modelValue[item.prop]" type="date" :placeholder="item.label"></el-date-picker>
					</el-form-item>
					<el-form-item :key="index" v-else-if="item.type === 'datetime'">
						<el-date-picker v-model="modelValue[item.prop]"  type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" :start-placeholder="item.label+'-开始时间'" :end-placeholder="item.label+'-结束时间'"></el-date-picker>
					</el-form-item>
					<el-form-item :key="index" v-else>
						<el-input v-model="modelValue[item.prop]" :placeholder="item.label" :clearable="true"></el-input>
					</el-form-item>
				</template>
			</template>
			<el-form-item>
				<el-button type="primary" icon="Search" @click="search">查询</el-button>
				<el-button type="" icon="Delete" @click="clean">清空</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script >
export default {
	props:{
		column:{
			default:[],
			type:Array,
		},
		value:{},
	},
	computed:{
		modelValue:{
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		}
	},
	methods:{
		search(){
			this.$emit('search')
		},
		clean(){
			this.$emit('clean')
		}
	}
}

</script>
