import store from "@/store";
import {auth, views} from "@/api/views.js";
import {createUUID} from "@/common/string.js";
import {site_config} from "@/config/index.js";

export const createViewLog = (params)=>{
	params = Object.assign({},{
		uuid:getUUID(),
		source: 1,
		title: document.title,
		ua: navigator.userAgent,
		url: window.location.href,
	},params)
	views.log(params).then(()=>{})
}

export const getUUID = ()=>{
	let uuid = localStorage.getItem('UUID');
	if(!uuid){
		uuid = createUUID();
		localStorage.setItem('UUID',uuid);
	}
	return uuid;

}
export const doViewsLogin = async ()=>{
	if(!getViewsToken()){
		const uuid = getUUID();
		const p1 = await auth.login({
			uuid:uuid,
		})
		const token = p1.token;
		store.commit('views/setToke',token)
	}
}

export const getViewsToken = ()=>{
	return store.getters['views/getToken']
}

export const setTitle = (title )=>{
	document.title = title || site_config.title;
}
