import {getAdminToken} from "@/service/admin/common.js";

export default [
	{
		path: '/admin/auth/login',
		component: ()=> import('@/views/admin/auth/Login.vue'),
		meta: {title: '登录'},
	},
	{
		path:"/admin",
		component:()=> import('@/layout/Admin.vue'),
		children:[
			{
				path: '/admin',
				redirect:"/admin/dashboard"
			},
			{
				path: '/admin/dashboard',
				component: ()=> import('@/views/admin/dashboard/Index.vue'),
				meta: {title: '控制台'},
			},
			{
				path: '/admin/admin',
				component: ()=> import('@/views/admin/admin/Index.vue'),
				meta: {title: '管理员'},
			},
			{
				path: '/admin/plan',
				component: ()=> import('@/views/admin/plan/Index.vue'),
				meta: {title: '日程管理'},
			},
			{
				path: '/admin/speaker',
				component: ()=> import('@/views/admin/speaker/Index.vue'),
				meta: {title: '嘉宾管理'},
			},
			{
				path: '/admin/video',
				component: ()=> import('@/views/admin/video/Index.vue'),
				meta: {title: '产品介绍'},
			},
			{
				path: '/admin/file',
				component: ()=> import('@/views/admin/file/Index.vue'),
				meta: {title: '白皮书管理'},
			},
			{
				path: '/admin/*',
				name: 'Error',
				component: ()=> import('@/views/admin/Error.vue'),
				meta: {title: '错误'},
			}
		],
		beforeEnter:(to,from,next)=>{
			if(!getAdminToken()){
				next('/admin/auth/login')
			}else{
				next();
			}
		}
	}
]
