<template>
	<el-table ref="multipleTable" stripe :data="table_data" row-key="id" tooltip-effect="dark" v-loading="table_loading" :tree-props="{children: 'children', hasChildren: 'hasChildren'}" @selection-change="selection" @sort-change="sortChange">
		<el-table-column type="selection" width="55"></el-table-column>
		<el-table-column v-for="(item,index) in filter_column" :key="index" v-bind="item" >
			<template slot-scope="scope">
				<template v-if="item.type">
					<el-link v-if="item.type === 'url'" type="primary" :href="scope.row[item.prop]">{{scope.row[item.prop]}}</el-link>
					<table-option v-else-if="item.type === 'option'" :option="item.option" :value="scope.row[item.prop]"/>
					<div v-else-if="item.type === 'cascade'" >{{getCascade(item.option,scope.row[item.prop])}}</div>
					<div v-else-if="item.type === 'textarea'" class="textarea">{{scope.row[item.prop]}}</div>
					<table-image v-else-if="item.type === 'image'" :src="scope.row[item.prop]"/>
					<table-date-time v-else-if="item.type === 'datetime'" :value="scope.row[item.prop]"/>
					<table-date-time v-else-if="item.type === 'date'" :value="scope.row[item.prop]" format="YYYY-MM-DD"/>
					<div v-else-if="item.type === 'datetimerange'">
						<template v-if="scope.row['start_time'] && scope.row['end_time']">
							<table-date-time :value="scope.row['start_time']"/>
							<br/>-
							<table-date-time :value="scope.row['end_time']"/>
						</template>
						<template v-else>长期有效</template>
					</div>
					<table-sort v-if="item.type === 'sort'" :number="scope.row[item.prop]" @change="(sort)=>changeSort(sort,scope.row)"/>
					<div v-else-if="item.type === 'action'" class="el-link-group">
						<slot name="action" :scope="scope"/>
					</div>
					<template v-else>
						<slot name="column" :scope="scope" :item="item"/>
					</template>
				</template>
				<template v-else>
					<span>{{scope.row[item.prop]}}</span>
				</template>
			</template>
		</el-table-column>
	</el-table>
</template>

<script >
import {inArray} from "@/common/array.js";

export default {
	props:{
		table_loading:{
			type:Boolean,
			default:false,
		},
		column:{
			type:Array,
			default:[]
		},
		column_visible:{
			type:Array,
			default:[]
		},
		table_data:{
			type:Array,
			default:[]
		}
	},
	computed:{
		filter_column(){
			return this.column.filter((item,index)=>{
				return this.visibleColumn(item.prop);
			})
		}
	},
	methods:{
		getCascade(option,value){
			const checkList = (list, id, path = [])=>{
				if (!list){
					return []
				}
				for (const data of list) {
					path.push(data.title)
					if (data.id === id){
						return path;
					}
					if (data.children && data.children.length) {
						const find_children = checkList(data.children, id, path)
						if (find_children.length){
							return find_children
						}
					}
					path.pop()
				}
				return []
			}
			const arr = checkList(option,value).filter(Boolean);
			return arr.join('-')
		},
		sortChange({column, prop, order}){
			this.$emit('sortChange',{column, prop, order})
		},
		selection(selection_list){
			this.$emit('selection',selection_list)
		},
		visibleColumn(prop){
			return inArray(this.column_visible,prop)
		},
		changeSort(sort,item){
			this.$emit('changeSort',{sort,item})
		}
	}
}
</script>

<style lang="scss" scoped>
.textarea{white-space: pre-line;}
</style>

