import Vue from 'vue';
import Toast from 'vant/lib/toast';
import Dialog from 'vant/lib/dialog';
import 'vant/lib/toast/style';
import 'vant/lib/dialog/style';

Toast.setDefaultOptions('loading', {
	forbidClick: true,
	duration:0,
});

Vue.use(Toast);
Vue.use(Dialog);
