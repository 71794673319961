import {getUrlParams} from "./url.js"

export const getScrollbarWidth = ()=>{
	let scrollDiv = document.createElement('div');
	scrollDiv.style.cssText = 'width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;';
	document.body.appendChild(scrollDiv);
	let scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
	document.body.removeChild(scrollDiv);
	return scrollbarWidth;
}

export const setRem = ()=>{
	let __resize = ()=>{
		var rate;
		var scrollbarWidth = getScrollbarWidth();
		var clientWidth = document.documentElement.clientWidth + scrollbarWidth;
		if(clientWidth > 992){
			rate = 19.2;
		}else if(clientWidth <= 992 && clientWidth > 750){
			rate = 7.5;
		}else{
			rate = 4.14;
		}
		document.documentElement.style.fontSize = clientWidth / rate + 'px';
	}
	window.addEventListener("onorientationchange" in window ? "orientationchange" : "resize", ()=>{
		setTimeout(()=>{__resize();},100);
		setTimeout(()=>{__resize();},300);
	}, false);
	__resize();
}

export const setFont = ()=>{
	let dom = document.createElement('div');
	dom.getRootNode().style = 'font-size: 10px';
	document.body.appendChild(dom);
	let scaledFontSize = parseInt(window.getComputedStyle(dom, null).getPropertyValue('font-size'));
	document.body.appendChild(dom);
	let scaleFactor = 10 / scaledFontSize;
	let originRootFontSize = parseInt(window.getComputedStyle(document.documentElement, null).getPropertyValue('font-size'));
	document.documentElement.style.fontSize = originRootFontSize * scaleFactor * scaleFactor + 'px';
}

export const isDev = ()=>{
	return process.env.NODE_ENV === 'development'
}

export const isDebug = ()=>{
	return !!getUrlParams('debug');
}

/**
 * 滚动到指定位置
 * @param number
 * @param time
 * @returns {number}
 */
export const scrollTop = (number = 0, time = 0)=>{
	if (!time) {
		document.body.scrollTop = document.documentElement.scrollTop = number;
		return number;
	}
	var spacingTime = 20; // 设置循环的间隔时间  值越小消耗性能越高
	var spacingIndex = time / spacingTime; // 计算循环的次数
	var nowTop = document.body.scrollTop + document.documentElement.scrollTop; // 获取当前滚动条位置
	var everTop = (number - nowTop) / spacingIndex; // 计算每次滑动的距离
	var scrollTimer = setInterval(() => {
		if (spacingIndex > 0) {
			spacingIndex--;
			this.scrollTop(nowTop += everTop);
		} else {
			clearInterval(scrollTimer); // 清除计时器
		}
	}, spacingTime);
}

/**
 * 获取16进制随机颜色
 * @returns {string}
 */
export const getRandomColor = ()=>{
	return '#' + (function (h) {
		return new Array(7 - h.length).join("0") + h;
	})((Math.random() * 0x1000000 << 0).toString(16));
}


/**
 * 批量图片加载
 * @param arr
 * @param progress
 * @returns {Promise<unknown>}
 */
export const loadImages = (arr, progress) => {
	let percent = 0;
	let one = (src) => {
		return new Promise((resolve, reject) => {
			let image = new Image();
			image.src = src;
			image.onload = () => {
				resolve();
				percent++;
				progress && progress(percent);
			}
		})
	}
	let all = [];
	arr.map((item, index) => {
		all.push(one(item))
	})
	return new Promise((resolve, reject) => {
		Promise.all(all).then((res) => {
			resolve(res);
		})
	})
}


