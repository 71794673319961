<template>
	<el-checkbox-group v-model="modelValue">
		<el-checkbox :value="item[id] || item" :label="item[title] || item" v-for="(item,index) in option" :key="index"></el-checkbox>
	</el-checkbox-group>
</template>

<script >
export default {
	props:{
		value:"",
		option:{
			default:[],
		},
		id:{
			default:"id",
			type:String
		},
		title:{
			default:"title",
			type:String
		}
	},
	computed:{
		modelValue:{
			get(){
				return this.value ? this.value.split(',') : []
			},
			set(value){
				this.$emit('input',value.join(','))
			}
		}
	}
}
</script>
