import Vue from 'vue'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'

Vue.directive('scroll-lock', {
	bind( el, binding ) {
		if (binding.value) {
			disableBodyScroll(el)
		}
	},
	update( el, binding ) {
		if (binding.value) {
			disableBodyScroll(el)
		} else {
			enableBodyScroll(el)
		}
	},
	unbind( el ) {
		enableBodyScroll(el)
	}
})
