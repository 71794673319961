<template>
	<span>
		<el-input v-model="modelValue" class="input-with-select" :prefix-icon="modelValue">
			<el-button slot="append" icon="el-icon-paperclip" @click="showIcon"></el-button>
		</el-input>
		<components-icon-list ref="iconRef" @choose="choose"/>
	</span>
</template>

<script >
import ComponentsIconList from "./iconinput/IconList.vue"

export default {
	components:{ComponentsIconList},
	props:{
		value:"",
	},
	computed:{
		modelValue:{
			get(){
				return this.value
			},
			set(value){
				this.$emit('input',value)
			}
		}
	},
	methods:{
		showIcon(){
			this.$refs['iconRef'].init();
		},
		choose(name){
			this.$emit('input',name)
		}
	}
}
</script>
