<template>
	<el-cascader :options="area" :props="props" v-model="modelValue" class="el-block"></el-cascader>
</template>

<script >
import { area } from '@/common/area'

export default {
	props:{
		value:"",
	},
	data(){
		return {
			options: [],
			props: {
				value: 'label',
				label: 'label',
				children: 'children'
			},
		}
	},
	computed:{
		area(){
			return area
		},
		modelValue:{
			get() {
				return this.value ? this.value.split(',') : []
			},
			set(value) {
				this.$emit('input', value.join(','))
			}
		}
	}
}
</script>

