import Vue from "vue";

import TableColumn from "@/components/admin/table/Column.vue"
import TableDateTime from "@/components/admin/table/DateTime.vue"
import TableImage from "@/components/admin/table/Image.vue"
import TableOption from "@/components/admin/table/Option.vue"
import TableQrcode from "@/components/admin/table/Qrcode.vue"
import TableSearch from "@/components/admin/table/Search.vue"
import TableSort from "@/components/admin/table/Sort.vue"
import TableTable from "@/components/admin/table/Table.vue"
import TableImport from "@/components/admin/table/Import.vue"

import FormCheckBox from "@/components/admin/form/CheckBox.vue"
import FormDistrict from "@/components/admin/form/District.vue"
import FormDuration from "@/components/admin/form/Duration.vue"
import FormEditor from "@/components/admin/form/Editor.vue"
import FormLink from "@/components/admin/form/Link.vue"
import FormTagInput from "@/components/admin/form/TagInput.vue"
import FormUpload from "@/components/admin/form/Upload.vue"
import FormIconInput from "@/components/admin/form/IconInput.vue"
import FormList from "@/components/admin/form/List.vue"

Vue.component('TableColumn',TableColumn);
Vue.component('TableDateTime',TableDateTime);
Vue.component('TableImage',TableImage);
Vue.component('TableOption',TableOption);
Vue.component('TableQrcode',TableQrcode);
Vue.component('TableSearch',TableSearch);
Vue.component('TableSort',TableSort);
Vue.component('TableTable',TableTable);
Vue.component('TableImport',TableImport);

Vue.component('FormCheckBox',FormCheckBox);
Vue.component('FormDistrict',FormDistrict);
Vue.component('FormDuration',FormDuration);
Vue.component('FormEditor',FormEditor);
Vue.component('FormLink',FormLink);
Vue.component('FormTagInput',FormTagInput);
Vue.component('FormUpload',FormUpload);
Vue.component('FormIconInput',FormIconInput);
Vue.component('FormList',FormList);
