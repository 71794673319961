<template>
	<main>
		<div class="image-components" :class="[src ? '' : 'none','rate-'+rate]">
			<img class="image" v-if="src" :src="src" :alt="alt">
		</div>
	</main>
</template>

<script>
export default {
	props:{
		src:{
			type:String,
			default:""
		},
		alt:{
			type:String,
			default: ""
		},
		rate:{
			type:Number,
			default:56,
		}
	}
}
</script>

<style lang="scss" scoped>
.image-components{position: relative;width:100%;overflow:hidden;
	.image{position: absolute;top: 0;left: 0;width: 100%;height: 100%;object-fit: cover;}
}
.image-components.none{background: #efefef;}
.image-components::before{display: block;content: '';width: 100%;}
@for $i from 1 through 200{
	.image-components.rate-#{$i}::before{padding-top: #{$i+'%'}}
}

</style>

