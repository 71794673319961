<template>
    <div id="app">
		<router-view></router-view>
    </div>
</template>

<script>
export default {
	components:{},
	data() {
		return {
			moveName: 'fade',
		}
	}
}
</script>

<style lang="scss" >
    @import 'assets/css/reset.scss';
</style>
