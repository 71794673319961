import Vue from 'vue'
import VueRouter from 'vue-router'
import _ from "lodash"

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => err)
}

const createRouteName = (route)=>{
    var list = route.default || route;
    list.map((item,index)=>{
        var path = item.path.split('/');
        if(path && path.length > 0){
            path.map((v,i)=>{
                path[i] = _.capitalize(v);
            })
        }
        item.name = path.join('');
        if(item.children){
            item.children = createRouteName(item.children)
        }
    })
    return list;
}

var routes = []
var routerContext

routerContext = require.context('./modules/', false, /.*.js$/)

routerContext.keys().forEach(route => {
    var routerModule = routerContext(route);
    routerModule = createRouteName(routerModule);
    routes = [...routes, ...(routerModule.default || routerModule)]
})


const router = new VueRouter({
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes
})

export default router
