import {isDev} from "@/common/other";

export const site_config = {
    title:process.env.VUE_APP_TITLE,
    cookie_prefix:process.env.VUE_APP_COOKIE_PREFIX || 'cookie',
    json_host:process.env.VUE_APP_JSON_HOST,
    json_dir:process.env.VUE_APP_JSON_DIR,
}
export const ext_config = {
    file:".pdf,.ppt,.doc,.docx,.word,.xlxs,.xls",
    image:".jpg,.jpeg,.png,.gif,.svg,.tiff",
    video:".mp4,.mov,.ogg",
}

export const host_config = {
    api:isDev() ? process.env.VUE_APP_API_HOST : process.env.VUE_APP_API_HOST,
}

export const book_config = {
    book:"https://insightchina.lenovonetapp.com/100377/inpc2024/index.html?customFormId=2410101117496014&instanceId=944027&linkId=346621&configId=3461910&tenantId=100377&webinarId=139837&sourceKey=webinar_139178&tc={tc}#/pc_index?randNum=1703152473",
    login:"https://insightchina.lenovonetapp.com/100377/inpc2024/index.html?customFormId=2410101117496014&instanceId=944027&linkId=346621&configId=3461910&tenantId=100377&webinarId=139837&sourceKey=webinar_139178&tc={tc}#/pc_verify?randNum=1703152473"
}
