import axios from 'axios'
import {host_config} from "@/config/index.js"
import { isDev} from "@/common/other"
import {getViewsToken} from "@/service/views/common.js";
import store from "@/store";
import router from "@/router";

const requestInstance = axios.create({
	timeout: 30*1000,
})

requestInstance.interceptors.request.use(config => {
	let baseURL = host_config.api;
	if(isDev()){
		baseURL = '/proxy'
	}
	config.baseURL = baseURL;
	config.headers['Content-Type'] = config.headers['Content-Type'] || 'application/json';
	config.headers['Token'] = getViewsToken();
	return config
}, error => {
	return Promise.reject(error)
})

requestInstance.interceptors.response.use((response) => {
		let message;
		let res = response.data;
		switch (res.code){
			case 1:
				return Promise.resolve(res.data);
			default:
				message = `${res.msg || 'Error'}`
				return Promise.reject({code:res.code,message:message});
		}
	},
	async (error) => {
		let message = error.message;
		let response = "";
		if(error && error.response){
			response = error.response;
			let status = response.status;
			let statusText = response.statusText;
			let path = "";
			if(error.response.config){
				path =response.config.baseURL + response.config.url;
			}
			switch (status){
				case 403:
					store.commit('views/setToken','')
					router.go(0)
					break;
				case 500:
					message = `${statusText} : ${path}`;
					break;
				default:
					message = `${error.message || 'Error'} : ${path}`
					break;
			}
		}
		return Promise.reject({code:response.status,message:message})
	}
)

export const request = requestInstance;
