<template>
	<main>
		<el-dialog :close-on-click-modal="false"  title="选择附件" :visible="visible" :append-to-body="true" @close="close">
			<div class="search-box" >
				<el-form size="small" :inline="true" :model="search_data" @keydown.enter.native="search" class="demo-form-inline">
					<el-form-item >
						<el-input v-model="search_data.file_name" placeholder="文件名" :clearable="true"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
						<el-button type="" icon="el-icon-delete" @click="clean">清空</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="table-box">
				<el-table border :data="table_data" v-loading="table_loading" @sort-change="sortChange">
					<el-table-column prop="preview" label="预览"  width="120px">
						<template slot-scope="scope">
							<img class="image" v-if="isImage(scope.row.url)" :src="scope.row.url" alt="" :onerror="defaultImage" @click="preview(scope.row.url)">
							<a v-else :href="scope.row.url" class="link" target="_blank">
								<span class="icon"><i class="t">{{fileName(scope.row.url)}}</i></span>
							</a>
						</template>
					</el-table-column>
					<el-table-column prop="file_name" label="文件名"></el-table-column>
					<el-table-column prop="file_size" label="文件大小" width="120px">
						<template slot-scope="scope">
							<span>{{fileSize(scope.row.file_size)}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="image_size" label="图片尺寸" width="120px">
						<template slot-scope="scope">
							<span v-if="scope.row.image_width && scope.row.image_height">{{scope.row.image_width}} x {{scope.row.image_height}}</span>
							<span v-else>-</span>
						</template>
					</el-table-column>
					<el-table-column sortable prop="create_time" label="上传时间">
						<template slot-scope="scope">
							<span>{{formatTime(scope.row.create_time)}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="action" label="操作" width="120px">
						<template slot-scope="scope">
							<div class="el-link-group">
								<el-link type="primary" @click="choose(scope.row.url)">选择</el-link>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="page-box">
				<el-pagination @size-change="pageSize" @current-change="pageCurrent" :current-page="page_info.page" :page-sizes="[10, 15, 20, 50,100]" :page-size="page_info.limit" layout="total, sizes, prev, pager, next, jumper" :total="page_info.total" hide-on-single-page background></el-pagination>
			</div>
		</el-dialog>
		<component-preview v-if="preview_visible" :images="preview_image" @closeViewer="cancelPreview"/>
	</main>
</template>

<script >
import ComponentPreview from "@/components/admin/form/upload/Preview.vue"
import {attachment} from "@/api/admin.js";
import moment from "moment";

export default {
	components:{ComponentPreview},
	props:{
		accept:{
			type:String,
			default: ""
		}
	},
	data(){
		return {
			visible:true,
			preview_visible:false,
			preview_image:"",
			search_data: {},
			table_search:false,
			table_loading:false,
			order:"",
			page:1,
			limit:10,
			table_data:[],
			page_info:{
				total:0,
				page:1,
				limit:10,
			},

			defaultImage: ''
		}
	},
	computed:{
		accept_string(){
			return this.accept ? this.accept.replace(/\./g,'') : ''
		}
	},
	mounted() {
		this.all();
	},
	methods: {
		search() {
			this.all();
		},
		clean() {
			this.search_data = {}
			this.search()
		},
		sortChange( {column, order, prop}){
			this.order = order === 'descending' ? 1 : 2;
			this.all();
		},
		choose(url){
			this.$emit('choose',url)
		},
		fileName(file){
			var index = file.lastIndexOf(".");
			var ext = file.substring(index+1);
			return ext.substring(0,5)
		},
		isImage(url){
			const index= url.lastIndexOf(".");
			const ext = url.substr(index+1);
			const type = ['png', 'jpg', 'jpeg'];
			return type.indexOf(ext.toLowerCase()) > -1;
		},
		preview(url) {
			if(this.isImage(url)){
				this.preview_image = [url];
				this.preview_visible = true;
			}else{
				window.open(url,'_blank')
			}
		},
		cancelPreview() {
			this.preview_visible = false;
			this.preview_image = "";
		},
		pageSize(limit){
			this.page = 1;
			this.limit = limit;
			this.all();
		},
		pageCurrent(page){
			this.page = page;
			this.all();
		},
		fileSize(file_size){
			file_size = parseInt(file_size);
			var size = '-';
			if(!file_size){
				size = '0KB'
			}else if (file_size < 1024*1024){
				file_size = (file_size / 1024).toFixed(2)+'KB'
			}else{
				file_size = (file_size / (1024 * 1024)).toFixed(2)+'MB'
			}
			return file_size
		},
		all(){
			this.table_loading = true;
			var params = {
				page:this.page,
				limit:this.limit,
				order:this.order,
				accept:this.accept_string
			}
			params = Object.assign({},params,this.search_data)
			attachment.all(params).then((res)=>{
				this.table_data = res.list;
				this.page_info = res.page_info;
				this.table_loading = false;
			})
		},
		close(){
			this.$emit('close')
		},
		formatTime(time,format){
			if(!format){
				format = "YYYY-MM-DD HH:mm:ss"
			}
			return moment.unix(time).format(format)
		},
	},
}
</script>

<style lang="scss" scoped>
.table-box{max-height:50vh;overflow: hidden;overflow-y: auto;margin-top: 0;
	.image{display: block;max-width: 60px;max-height: 60px;padding: 5px; object-position:center center;object-fit: contain;cursor: pointer;background: #efefef;}
	.link{display: block;width: 60px;height:60px;
		.icon{display: block; width: 50px;height:50px;margin: 5px auto;position: relative;background: url("~@/assets/admin/image/file.png") no-repeat center center;background-size: 100% 100%;
			.t{position: absolute;top:24px;left:0;width: 40px;text-align: center; height:20px;line-height: 20px;font-size: 12px;font-weight: bold;color:#333;text-transform: uppercase;}
		}
	}
}
.page-box{padding-bottom: 15px;margin-top: 15px;}
</style>

