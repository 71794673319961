<template>
	<div class="item">
		<el-popover placement="bottom" width="400" trigger="click" popper-class	="column-visible">
			<div class="list">
				<el-checkbox-group v-model="column_visible">
					<el-checkbox class="li" :label="item.prop" name="column" v-for="(item,index) in column" :key="index" >{{item.label}}</el-checkbox>
				</el-checkbox-group>
			</div>
			<el-button slot="reference" class="el-icon-s-operation" title="字段"></el-button>
		</el-popover>
	</div>
</template>

<script>
import cookie from "js-cookie";
import {inArray} from "@/common/array";
import {site_config} from "@/config/index.js";

export default {
	props:{
		column:{
			default:[],
			type:Array
		},
	},
	data(){
		return {
			column_visible:[],
		}
	},
	watch:{
		column_visible(){
			this.$emit('change',this.column_visible)
			this.setColumnVisible()
		}
	},
	mounted() {
		this.getColumnVisible()
	},
	methods:{
		getColumnVisibleCookie(key){
			const d = cookie.get(`${site_config.cookie_prefix}_column_${key}`);
			return d ? JSON.parse(d) : '';
		},
		setColumnVisibleCookie(key,data = []){
			return cookie.set(`${site_config.cookie_prefix}_column_${key}`,JSON.stringify(data));
		},
		getColumnVisible(){
			const data = this.getColumnVisibleCookie(this.$route.path) || [];
			const list = [];
			this.column.map((item,index)=>{
				if(!inArray(data,item.prop)){
					list.push(item.prop)
				}
			})
			this.column_visible = list;
		},
		setColumnVisible(){
			const list = [];
			this.column.map((item,index)=>{
				if(!inArray(this.column_visible,item.prop)){
					list.push(item.prop)
				}
			})
			this.setColumnVisibleCookie(this.$route.path,list);
		}
	}
}
</script>
